<template>
  <md-table v-model="cadastro" @md-selected="onSelect">
    <div v-if="!isFetching">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div style="padding-top:15px;display:inline-block;">
            <span>
              <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
                <small>{{ tipoProprietario }} id: {{ form.idServidor }}</small>
              </h5></span
            >
          </div>
          <div class="switch-div" v-show="checkPapel()">
            <md-switch v-model="editionMode">Modo Edição </md-switch>
          </div>
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Situação Cadastral
            </h5></span
          >
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.situacao"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="situacaoSelect">Selecionar Situação</label>
                  <md-select
                    id="selectOptionFieldSituacao"
                    v-model="situacaoSelect"
                    name="situacaoSelect"
                    @md-selected="selectSituacao"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-option
                      v-for="item in situacaoSelectOption"
                      :key="item.id"
                      :label="item.situacao"
                      :value="item.id"
                    >
                      {{ item.situacao }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <!--<div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="form.nomeEntidade"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="entidadeSelect">Selecionar Entidade</label>
                  <md-select
                    v-if="!isFetching3"
                    v-model="entidadeSelect"
                    name="entidadeSelect"
                    id="entidadeSelect"
                    @md-selected="selectedEntidade"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-option
                      v-for="item in entidadeSelectOption"
                      :key="item.idEntidade"
                      :label="item.idEntidade"
                      :value="item.idEntidade"
                    >
                      {{ item.nomeEntidade }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div> -->
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="form.nomeEntidade"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-autocomplete
                  v-model="entidadeSelect"
                  :md-options="entidadeSelectOptionAuto"
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                  @md-selected="selectedEntidade"
                  :disabled="checkEditionDisabled()"
                >
                  <label>Selecionar Entidade</label>
                </md-autocomplete>

                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Dados Pessoais
            </h5></span
          >
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-100">
              <ValidationProvider
                name="form.nomeCompleto"
                :rules="{
                  regex: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1\']*$/,
                  min: 3,
                  max: 100,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Nome Completo</label>
                  <md-input
                    name="Nome Completo"
                    v-model="form.nomeCompleto"
                    type="text"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.cpfServidor"
                :rules="{
                  regex: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
                  min: 14,
                  max: 14,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>CPF</label>
                  <md-input
                    v-model="form.cpfServidor"
                    type="text"
                    :maxlength="14"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "CPF dever ser no formato 999.999.999-99" }}
                </span>
              </ValidationProvider>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.dataNascimento"
                :rules="{
                  regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                  min: 10,
                  max: 10,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data de Nascimento</label>
                  <md-input
                    v-model="form.dataNascimento"
                    type="text"
                    :maxlength="10"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Data deve ter formato DD/MM/AAAA" }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.dataObito"
                :rules="{
                  regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                  min: 10,
                  max: 10
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data de Obito</label>
                  <md-input
                    v-model="form.dataObito"
                    type="text"
                    :maxlength="10"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Data deve ter formato DD/MM/AAAA" }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.diaAniversario"
                :rules="{
                  regex: /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])$/,
                  min: 2,
                  max: 2,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Dia Aniversário</label>
                  <md-input
                    v-model="form.diaAniversario"
                    type="text"
                    :maxlength="2"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.mesAniversario"
                :rules="{
                  regex: /^(0[1-9]|1[0-2])$/,
                  min: 2,
                  max: 2,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Mês de Aniversário</label>
                  <md-input
                    v-model="form.mesAniversario"
                    type="text"
                    :maxlength="2"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-10">
              <md-field>
                <label>Idade</label>
                <md-input
                  v-model="idade"
                  type="numeric"
                  :maxlength="3"
                  disabled
                ></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="form.sexoServidor"
                rules="required"
                v-slot="{ errors }"
              >
                <label>Sexo </label>
                <md-radio
                  v-model="form.sexoServidor"
                  value="M"
                  :disabled="checkEditionDisabled()"
                  >Masculino</md-radio
                >
                <md-radio
                  v-model="form.sexoServidor"
                  value="F"
                  :disabled="checkEditionDisabled()"
                  >Feminino</md-radio
                >
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100"></div>

            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="form.estadoCivil"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="estadoCivilSelect">Selecionar Estado Civil</label>
                  <md-select
                    id="selectOptionFieldEstadoCivil"
                    v-model="estadoCivilSelect"
                    name="estadoCivilSelect"
                    @md-selected="selectEstadoCivil"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-option
                      v-for="item in estadoCivilOptions"
                      :key="item.id"
                      :label="item.estadoCivil"
                      :value="item.id"
                    >
                      {{ item.estadoCivil }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="form.nivelEscolaridade"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="nivelEscolaridadeSelect"
                    >Selecionar Nível Escolaridade</label
                  >
                  <md-select
                    id="selectOptionFieldNivelEscolaridade"
                    v-model="nivelEscolaridadeSelect"
                    name="nivelEscolaridadeSelect"
                    @md-selected="selectNivelEscolaridade"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-option
                      v-for="item in nivelEscolaridadeOptions"
                      :key="item.id"
                      :label="item.nivelEscolar"
                      :value="item.id"
                    >
                      {{ item.nivelEscolar }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.nomeMae"
                rules="required|min:3|max:200|alpha_spaces"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Nome da Mãe</label>
                  <md-input
                    name="Nome da Mãe"
                    v-model="form.nomeMae"
                    type="text"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Dados Complementares
            </h5></span
          >
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="form.rgServidor"
                :rules="{
                  min: 8,
                  max: 12
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>RG</label>
                  <md-input
                    v-model="form.rgServidor"
                    type="text"
                    :maxlength="12"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-10">
              <ValidationProvider
                name="form.rgUfServidor"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="rgUfServidorSelect">UF do RG</label>
                  <md-select
                    id="selectOptionFieldRgUf"
                    v-model="rgUfServidorSelect"
                    name="rgUfServidorSelect"
                    @md-selected="selectRgUfServidor"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-option
                      v-for="item in estados"
                      :key="item.shortname"
                      :label="item.name"
                      :value="item.shortname"
                    >
                      {{ item.shortname }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.rgOrgExpedidorServ"
                :rules="{
                  min: 2,
                  max: 10
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Orgão Expedidor</label>
                  <md-input
                    v-model="form.rgOrgExpedidorServ"
                    type="text"
                    :maxlength="10"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="form.rgDataExpServ"
                :rules="{
                  min: 10,
                  max: 10
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data de emisão do RG</label>
                  <md-input
                    v-model="form.rgDataExpServ"
                    type="text"
                    :maxlength="10"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100"></div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.numTitEleitor"
                :rules="{
                  regex: /(\d{4}).(\d{4}).[0-2][0-9](\d{2})/,
                  min: 14,
                  max: 14
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Titulo Eleitor</label>
                  <md-input
                    v-model="form.numTitEleitor"
                    type="text"
                    :maxlength="14"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Titulo Eleitor deve ter 14 digitos." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.ctpsNum"
                :rules="{
                  regex: /^(?:\d{6}|\d{7})$/,
                  numeric: true,
                  min: 6,
                  max: 7
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Carteira de Trabalho</label>
                  <md-input
                    v-model="form.ctpsNum"
                    type="text"
                    :maxlength="7"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.ctpsSerie"
                :rules="{
                  regex: /^(?:\d{2}|\d{4})$/,
                  numeric: true,
                  min: 2,
                  max: 4
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Serie CTPS</label>
                  <md-input
                    v-model="form.ctpsSerie"
                    type="text"
                    :maxlength="4"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-10">
              <ValidationProvider
                name="form.ctpsUf"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="ctpsUfSelect">UF da CTPS</label>
                  <md-select
                    id="selectOptionFieldCtpsUf"
                    v-model="ctpsUfSelect"
                    name="ctpsUfSelect"
                    @md-selected="selectCtpsUf"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-option
                      v-for="item in estados"
                      :key="item.shortname"
                      :label="item.name"
                      :value="item.shortname"
                    >
                      {{ item.shortname }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.pisPasep"
                :rules="{
                  regex: /^(\d{3}).(\d{5}).(\d{2})-(\d{1})$/,
                  min: 14,
                  max: 14
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>PIS/PASEP</label>
                  <md-input
                    v-model="form.pisPasep"
                    type="text"
                    :maxlength="14"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "PIS/PASEP deve ter 14 digitos." }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <div class="md-layout">
            <div class="md-layout-item md-size-100 text-right">
              <md-button
                type="submit"
                v-show="showButton"
                class="md-raised md-success mt-4"
              >
                ATUALIZAR
              </md-button>
              &nbsp;
              <md-button
                type="button"
                v-show="showButton"
                class="md-raised mt-4"
                @click="cancelarButton"
              >
                CANCELAR
              </md-button>
              <span>&nbsp;</span>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </md-table>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import { NavTabsCard } from "@/components";
import Vue from "vue";
import Swal from "sweetalert2";
import { cpfMask } from "@/pages/Dashboard/js/cpfMask";
import { dataMask } from "@/pages/Dashboard/js/dataMask";
import { tituloEleitorMask } from "@/pages/Dashboard/js/tituloEleitorMask";
import { pispasepMask } from "@/pages/Dashboard/js/pispasepMask";
import { ESTADOS } from "@/pages/Dashboard/js/estados";
import { SITUACAO } from "@/pages/Dashboard/js/situacao";
import { ESTADO_CIVIL } from "@/pages/Dashboard/js/estadoCivil";
import { NIVEL_ESCOLARIDADE } from "@/pages/Dashboard/js/nivelEscolaridade";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  if (key === "regex") {
    pt_br.messages.regex = "Campo com formato inválido.";
  }
}

var fields = {
  "form.nomeCompleto": {
    regex:
      "O campo Nome Completo não pode ter numeros, nem caracteres especiais."
  },
  "form.ctpsNum": {
    regex: "Carteira de Trabalho deve ter de 6 a 7 digitos."
  },
  "form.ctpsSerie": {
    regex: "Serie CTPS deve ter de 2 a 4 digitos."
  }
};

for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
Vue.set(pt_br, "fields", fields);
localize("pt_BR", pt_br);

export default {
  props: {
    id: {
      type: String
    },
    isEdition: {
      type: Boolean
    },
    tipoProprietario: { type: String }
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("servidor_publico", {
      servidorPublicoSelected: "servidorPublicoState"
    }),
    user_profile_state: state => state.user_profile
  },
  data() {
    return {
      entidadeSelect: "",
      entidadeSelectOptionAuto: [],
      entidadeSelectOption: [],
      selectedServidorId: this.id,
      cadastro: null,
      situacaoSelectOption: SITUACAO,
      estadoCivilOptions: ESTADO_CIVIL,
      nivelEscolaridadeOptions: NIVEL_ESCOLARIDADE,
      isFetching: true,
      isFetching3: true,
      showButton: false,
      editionMode: false,
      estados: ESTADOS,
      isDisabledEdition: true,
      nivelEscolaridadeSelect: "",
      estadoCivilSelect: "",
      situacaoSelect: "",
      rgUfServidorSelect: "",
      ctpsUfSelect: "",
      idade: 0,
      radioSexo: false,
      status: false,
      mensagem: "",
      form: {
        idServidor: this.id,
        idEntidade: null,
        nomeEntidade: null,
        nomeCompleto: null,
        situacao: null,
        cpfServidor: null,
        numTitEleitor: null,
        ctpsNum: null,
        ctpsSerie: null,
        ctpsUf: null,
        estadoCivil: null,
        nivelEscolaridade: null,
        dataNascimento: null,
        dataObito: null,
        diaAniversario: null,
        mesAniversario: null,
        pisPasep: null,
        rgServidor: null,
        rgUfServidor: null,
        rgOrgExpedidorServ: null,
        rgDataExpServ: null,
        sexoServidor: null,
        nomeMae: null,
        siapeServidor: null,
        siapeServidorDv: null,
        codOrgao: null,
        orgDescricao: null,
        upag: null,
        upagDescricao: null,
        siglaUpag: null,
        ufUpag: null,
        grupoCargo: null,
        cargoDescricao: null,
        classeCargo: null,
        padraoCargo: null,
        nivelCargo: null,
        uorgLotacao: null,
        nomeUnidadeLotacao: null,
        siglaUnidadeLotacao: null,
        ufUnidadeLotacao: null,
        uorgLocalizacao: null,
        nomeUnidadeLocalizacao: null,
        siglaUnidadeLocalizacao: null,
        ufUnidadeLocalizacao: null,
        uorgExercicio: null,
        nomeUnidadeExercicio: null,
        siglaUnidadeExercicio: null,
        ufUnidadeExercicio: null,
        sigRegJur: null,
        regimeJuridico: null,
        descricaoIngOrgao: null,
        dataIngressoOrgao: null,
        descricaoIngServPublico: null,
        dtIngServPublico: null,
        grupoAposentadoria: null,
        codAposentadoria: null,
        ocorrenciaAposentadoria: null,
        dtAposentadoria: null,
        codExclusao: null,
        ocorrenciaExclusao: null,
        dtExclusao: null,
        dataModificacao: null,
        dataCriacao: null
      },
      papel: ""
    };
  },
  beforeMount() {
    this.getPapel();
    this.getEntidadeAll().then(() => {
      this.loadServidor();
    });
  },
  methods: {
    ...mapActions("papel", ["ActionGetPapelAll"]),
    ...mapActions("servidor_publico", [
      "ActionSetSERVIDOR_PUBLICO",
      "ActionGetServidorPublicoById",
      "ActionPUTServidorPublico"
    ]),
    ...mapActions("entidade", [
      "ActionGetEntidadeById",
      "ActionGetEntidadeAll"
    ]),
    checkPapel() {
      return this.papel === "ADMIN" ? true : false;
    },
    async loadForm() {
      this.form.idServidor = this.servidorPublicoSelected.idServidor;
      this.form.idEntidade = this.servidorPublicoSelected.idEntidade;
      this.form.nomeEntidade = this.servidorPublicoSelected.nomeEntidade;

      if (this.entidadeSelectOption.length > 0) {
        //this.entidadeSelect = this.form.idEntidade;
        this.entidadeSelect = this.form.nomeEntidade;
      }
      this.form.nomeCompleto = this.servidorPublicoSelected.nomeCompleto;

      let situacao = this.servidorPublicoSelected.situacao;
      let index = -1;
      let resultItem = this.situacaoSelectOption.find(function(item, i) {
        if (item.situacao === situacao) {
          index = i;
          return item;
        }
      });
      this.form.situacao = resultItem.situacao;
      this.situacaoSelect = resultItem.id;

      try {
        this.form.cpfServidor = cpfMask(
          this.servidorPublicoSelected.cpfServidor.toString()
        );
      } catch (err) {
        console.log(err);
      }

      if (
        this.servidorPublicoSelected.dataNascimento !== null &&
        this.servidorPublicoSelected.dataNascimento !== "" &&
        typeof this.servidorPublicoSelected !== "undefined"
      ) {
        this.form.dataNascimento = this.servidorPublicoSelected.dataNascimento;
        this.idade = this.callIdadeCalc(this.form.dataNascimento);
      }
      this.radioSexo = this.servidorPublicoSelected.sexoServidor;
      this.form.sexoServidor = this.servidorPublicoSelected.sexoServidor;
      this.form.rgServidor = this.servidorPublicoSelected.rgServidor;

      if (
        this.servidorPublicoSelected.rgUfServidor !== null &&
        this.servidorPublicoSelected.rgUfServidor !== "" &&
        this.servidorPublicoSelected.rgUfServidor !== " " &&
        this.servidorPublicoSelected.rgUfServidor !== "  " &&
        typeof this.servidorPublicoSelected.rgUfServidor !== "undefined"
      ) {
        let rgUfServidor = this.servidorPublicoSelected.rgUfServidor;
        let index = -1;
        let resultItem = this.estados.find(function(item, i) {
          if (item.shortname === rgUfServidor) {
            index = i;
            return item;
          }
        });

        this.form.rgUfServidor = resultItem.shortname;
        this.rgUfServidorSelect = resultItem.shortname;
      } else {
        this.form.rgUfServidor = null;
        this.rgUfServidorSelect = null;
      }

      this.form.rgOrgExpedidorServ =
        this.servidorPublicoSelected.rgOrgExpedidorServ === null
          ? null
          : this.servidorPublicoSelected.rgOrgExpedidorServ.toString().trim();

      this.form.rgDataExpServ = this.servidorPublicoSelected.rgDataExpServ;
      this.form.estadoCivil = this.servidorPublicoSelected.estadoCivil;

      if (this.servidorPublicoSelected.nivelEscolaridade !== null) {
        let nivelEscolaridade = this.servidorPublicoSelected.nivelEscolaridade;
        let index2 = -1;
        let resultItem2 = this.nivelEscolaridadeOptions.find(function(item, i) {
          if (item.nivelEscolar === nivelEscolaridade) {
            index2 = i;
            return item;
          }
        });
        this.form.nivelEscolaridade = resultItem2.nivelEscolar;
        this.nivelEscolaridadeSelect = resultItem2.id;
      } else {
        this.form.nivelEscolaridade = null;
        this.nivelEscolaridadeSelect = null;
      }

      if (this.servidorPublicoSelected.estadoCivil !== null) {
        let estadoCivil = this.servidorPublicoSelected.estadoCivil;
        let index3 = -1;
        let resultItem3 = this.estadoCivilOptions.find(function(item, i) {
          if (item.estadoCivil === estadoCivil) {
            index3 = i;
            return item;
          }
        });
        this.form.estadoCivil = resultItem3.estadoCivil;
        this.estadoCivilSelect = resultItem3.id;
      } else {
        this.form.estadoCivil = null;
        this.estadoCivilSelect = null;
      }

      this.form.nomeMae = this.servidorPublicoSelected.nomeMae;
      this.form.dataObito = this.servidorPublicoSelected.dataObito;

      this.form.numTitEleitor =
        this.servidorPublicoSelected.numTitEleitor === null
          ? null
          : tituloEleitorMask(
              this.servidorPublicoSelected.numTitEleitor.toString()
            );

      this.form.ctpsNum = this.servidorPublicoSelected.ctpsNum;
      this.form.ctpsSerie = this.servidorPublicoSelected.ctpsSerie;

      if (this.servidorPublicoSelected.ctpsUf !== null) {
        let ctpsUf = this.servidorPublicoSelected.ctpsUf;
        let index = -1;
        let resultItem = this.estados.find(function(item, i) {
          if (item.shortname === ctpsUf) {
            index = i;
            return item;
          }
        });
        this.form.ctpsUf = resultItem.shortname;
        this.ctpsUfSelect = resultItem.shortname;
      } else {
        this.form.ctpsUf = null;
        this.ctpsUfSelectt = null;
      }

      this.form.pisPasep =
        this.servidorPublicoSelected.pisPasep === null
          ? null
          : pispasepMask(this.servidorPublicoSelected.pisPasep);

      this.form.diaAniversario = this.servidorPublicoSelected.diaAniversario;
      this.form.mesAniversario = this.servidorPublicoSelected.mesAniversario;
      this.isFetching = false;
      if (this.isEdition) {
        this.editionMode = true;
      }
      /*let copiedJSON = JSON.parse(JSON.stringify(this.entidadeSelectOption));
      copiedJSON.map(line => {
        delete line.idEntidade;
        delete line.descricaoEntidade;
        delete line.dataCriacao;
      });*/
      let copiedJSON = Object.keys(this.entidadeSelectOption).map(key => {
        return this.entidadeSelectOption[key].nomeEntidade;
      });

      this.entidadeSelectOptionAuto = copiedJSON;
    },

    async loadServidor() {
      if (
        typeof this.servidorPublicoSelected !== "undefined" &&
        this.servidorPublicoSelected !== "" &&
        this.servidorPublicoSelected !== null &&
        this.servidorPublicoSelected.idServidor
      ) {
        this.loadForm();
      } else {
        let id = this.id;
        let papel = this.papel;
        let path = window._Vue.$route.path;
        path = path.toString().split("/");
        if (path[3]) {
          id = path[3];
          const patt = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
          if (patt.test(id.toString())) {
            this.getServidorById(id, papel).then((res, papel) => {
              this.getEntidadeById(res.idEntidade).then(responseEntidade => {
                Vue.set(res, "idEntidade", responseEntidade.idEntidade);
                Vue.set(res, "nomeEntidade", responseEntidade.nomeEntidade);
                this.ActionSetSERVIDOR_PUBLICO(res);
                this.loadForm();
              });
            });
          } else {
            Swal.fire({
              title: "ATENÇÃO!",
              icon: "warning",
              text: "Por favor selecione um Servidor antes!",
              customClass: {
                confirmButton: "md-button md-warning btn-fill"
              },
              buttonsStyling: false
            });
            window._Vue.$router.push({ name: "Servidores" }).catch(err => {});
          }
        } else {
          Swal.fire({
            title: "ATENÇÃO!",
            icon: "warning",
            text: "Por favor selecione um Servidor antes!",
            customClass: {
              confirmButton: "md-button md-warning btn-fill"
            },
            buttonsStyling: false
          });
          window._Vue.$router.push({ name: "Servidores" }).catch(err => {});
        }
      }
    },
    async getEntidadeById(idEntidade) {
      try {
        let id = idEntidade;
        let papel = this.papel;
        let response = await this.ActionGetEntidadeById({ id, papel });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não encontrada")) {
              Swal.fire({
                title: `idEntidade: ${idEntidade} não encontrada.`,
                icon: "error",
                text: this.mensagem,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised md-danger btn-fill"
                },
                buttonsStyling: false
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `ERROR - idEntidade: ${idEntidade}`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          } else {
            return response.body;
          }
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    async getServidorById(idServidor, papelUsuario) {
      let id = idServidor;
      let papel = papelUsuario;
      let idEntidade = this.user_profile_state.idEntidade;
      try {
        let response = await this.ActionGetServidorPublicoById({
          id,
          papel,
          idEntidade
        });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não encontrado")) {
              Swal.fire({
                title: `idServidor: ${idServidor} não encontrado.`,
                icon: "error",
                text: this.mensagem,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised md-danger btn-fill"
                },
                buttonsStyling: false
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `ERROR - idServidor: ${idServidor}`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          } else {
            return response.body;
          }
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    selectCtpsUf(item) {
      let ctpsUf = item;
      let index = -1;
      let resultItem = this.estados.find(function(item, i) {
        if (item.shortname === ctpsUf) {
          index = i;
          return item;
        }
      });
      this.form.ctpsUf = resultItem.shortname;
    },
    selectRgUfServidor(item) {
      let rgUfServidor = item;
      let index = -1;
      let resultItem = this.estados.find(function(item, i) {
        if (item.shortname === rgUfServidor) {
          index = i;
          return item;
        }
      });
      this.form.rgUfServidor = resultItem.shortname;
    },
    selectEstadoCivil(item) {
      let estadoCivil = item;
      let index3 = -1;
      let resultItem3 = this.estadoCivilOptions.find(function(item, i) {
        if (item.id === estadoCivil) {
          index3 = i;
          return item;
        }
      });
      this.form.estadoCivil = resultItem3.estadoCivil;
    },
    selectNivelEscolaridade(item) {
      let nivelEscolaridade = item;
      let index2 = -1;
      let resultItem2 = this.nivelEscolaridadeOptions.find(function(item, i) {
        if (item.id === nivelEscolaridade) {
          index2 = i;
          return item;
        }
      });
      this.form.nivelEscolaridade = resultItem2.nivelEscolar;
    },
    onSelect(item) {
      console.log(item);
    },
    selectSituacao(item) {
      let situacao = item;
      let index = -1;
      let resultItem = this.situacaoSelectOption.find(function(item, i) {
        if (item.id === situacao) {
          index = i;
          return item;
        }
      });
      this.form.situacao = resultItem.situacao;
    },
    calcularIdade(dobString) {
      let dob = new Date(dobString);
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let birthdayThisYear = new Date(
        currentYear,
        dob.getMonth(),
        dob.getDate()
      );
      let age = currentYear - dob.getFullYear();
      if (birthdayThisYear > currentDate) {
        age--;
      }
      return age;
    },
    callIdadeCalc(dataNascimento) {
      if (dataNascimento.toString().length < 10) {
        return 0;
      }
      let data = dataNascimento;
      let partes = data.split("/");
      let join = partes[2] + "-" + partes[1] + "-" + partes[0];
      return this.calcularIdade(join);
    },
    VerificaCPF(strCPF) {
      let Soma;
      let Resto;
      Soma = 0;
      if (strCPF == "00000000000") return false;

      for (i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
    },
    checkEditionDisabled() {
      if (this.isDisabledEdition === true) {
        return "disabled";
      } else {
        return null;
      }
    },
    async submit() {
      try {
        let id = this.form.idServidor;
        let papel = this.papel;
        let form = this.form;
        let response = await this.ActionPUTServidorPublico({ id, papel, form });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não")) {
              Swal.fire({
                //title: "Sucesso",
                icon: "warning",
                text: this.mensagem,
                showCancelButton: false,
                customClass: {
                  confirmButton: "md-button md-success btn-fill"
                },
                buttonsStyling: false,
                confirmButtonText: "Ok"
              });
            } else {
              Swal.fire({
                //title: "Sucesso",
                icon: "success",
                text: this.mensagem,
                showCancelButton: false,
                customClass: {
                  confirmButton: "md-button md-success btn-fill"
                },
                buttonsStyling: false,
                confirmButtonText: "Ok"
              });
              this.editionMode = false;
              /*Object.keys(this.form).map(key => {
                console.log(key);
                return this.form[key];
              });*/
              this.ActionSetSERVIDOR_PUBLICO("");
              this.loadServidor();
            }
          } else {
            Swal.fire({
              //title: "Sucesso",
              icon: "warning",
              text: "OPSSS!! Algo deu Errado.",
              showCancelButton: false,
              customClass: {
                confirmButton: "md-button md-success btn-fill"
              },
              buttonsStyling: false,
              confirmButtonText: "Ok"
            });
          }
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    emitToParent(value) {
      this.$emit("edition", value);
    },
    cancelarButton() {
      this.loadServidor();
    },
    selectedEntidade(item) {
      let nomeEntidade = item;
      let index = -1;
      let resultItem = this.entidadeSelectOption.find(function(item, i) {
        if (item.nomeEntidade === nomeEntidade) {
          index = i;
          return item;
        }
      });
      this.form.idEntidade = resultItem.idEntidade;
      this.form.nomeEntidade = item;
    },
    async getEntidadeAll() {
      try {
        let papel = this.papel;
        let idEntidade = this.user_profile_state.idEntidade;

        await this.ActionGetEntidadeAll({ papel, idEntidade }).then(res => {
          if (this.papel === "ADMIN") {
            let result = res.body;
            result.sort((a, b) => {
              if (a.nomeEntidade < b.nomeEntidade) return -1;
              if (a.nomeEntidade > b.nomeEntidade) return 1;
              return 0;
            });
            this.entidadeSelectOption = result;
          } else {
            this.entidadeSelectOption = [];
            this.entidadeSelectOption.push({
              nomeEntidade: this.user_profile_state.nomeEntidade
            });
            this.entidadeSelect = this.user_profile_state.nomeEntidade;
          }
          this.isFetching3 = false;
          return this.entidadeSelectOption;
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  watch: {
    "form.cpfServidor": function(val, oldVal) {
      let cpf = cpfMask(val);
      this.form.cpfServidor = cpf;
    },
    "form.dataNascimento": function(val, oldVal) {
      let data = dataMask(val.toString());
      this.form.dataNascimento = data;
      this.idade = this.callIdadeCalc(this.form.dataNascimento);
      let diaMes = this.form.dataNascimento.split("/");
      this.form.diaAniversario = diaMes[0];
      this.form.mesAniversario = diaMes[1];
    },
    "form.dataObito": function(val, oldVal) {
      let data = dataMask(val.toString());
      this.form.dataObito = data;
    },
    "form.rgUfServidor": function(val, oldVal) {
      let rgUF = val.toString().toUpperCase();
      this.form.rgUfServidor = rgUF;
    },
    "form.rgDataExpServ": function(val, oldVal) {
      let data = dataMask(val.toString());
      this.form.rgDataExpServ = data;
    },
    "form.nomeCompleto": function(val, oldVal) {
      let nomeCompleto = val.toString().toUpperCase();
      this.form.nomeCompleto = nomeCompleto;
    },
    "form.nomeMae": function(val, oldVal) {
      let nomeMae = val.toString().toUpperCase();
      this.form.nomeMae = nomeMae;
    },
    "form.numTitEleitor": function(val, oldVal) {
      let numTitEleitor = tituloEleitorMask(val.toString());
      this.form.numTitEleitor = numTitEleitor;
    },
    editionMode: function(val, oldVal) {
      let edition = val;
      if (edition) {
        this.isDisabledEdition = false;
        this.showButton = true;
      } else {
        this.isDisabledEdition = true;
        this.showButton = false;
      }
      this.emitToParent(edition);
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

#agrupamentoCampos {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}

::v-deep .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
  border-color: rgba(0, 0, 0, 1) !important;
}

::v-deep .md-input {
  text-transform: uppercase;
}

::v-deep .md-switch-label {
  font-weight: bold;
  color: rgba(14, 14, 14, 0.52);
}

::v-deep .md-switch.md-checked .md-switch-container {
  background-color: #49a54d !important;
}

::v-deep .md-switch.md-checked .md-switch-thumb {
  border: 1px solid #49a54d;
}

::v-deep .md-radio.md-checked .md-radio-container {
  border-color: #000000 !important;
}

::v-deep .md-radio.md-checked .md-radio-container:after {
  background-color: #000000 !important;
}

.switch-div {
  display: inline-block;
  float: right;
}
</style>
